<template>
	<v-row no-gutters class="feedback-box flex-column">
		<div class="feedback-header d-flex align-center px-3">
			<p class="heading-mf15">Contractor’s feedback</p>
			<p class="text-btn ma-0 ml-auto" v-if="show" v-on:click="show = !show">Hide</p>
			<p class="text-btn ma-0 ml-auto" v-else v-on:click="show = !show">Show</p>
		</div>
		<transition name="show-hide" tag="div">
			<div v-show="show" class="feedback-content pa-3">
				<div>
					<div>{{ value.comments }}</div>
					<div class="pt-4">
						<div class="d-flex align-center">
							<div v-if="value.reason_ids.includes(1)" class="reason-icon d-flex align-center justify-center">$</div>
							<div v-if="value.reason_ids.includes(1)" class="reason-text pr-6 pl-2">Price is too high</div>

							<div v-if="value.reason_ids.includes(2)" class="reason-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['DeliveryVan']" style="width: 22px" alt />
							</div>
							<div v-if="value.reason_ids.includes(2)" class="reason-text pr-6 pl-2">Delivery is too late</div>

							<div v-if="value.reason_ids.includes(3)" class="reason-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Cube']" alt />
							</div>
							<div v-if="value.reason_ids.includes(3)" class="reason-text pr-6 pl-2">Not enough items</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</v-row>
</template>

<script>
export default {
	props: {
		value: Object,
	},
	data() {
		return {
			show: true,
		}
	},

	computed: {
		Show() {
			return this.show === false ? 'display:none' : 'display:block'
		},
	},
}
</script>

<style lang="scss">
    .feedback-box {
    	border-radius: 4px !important;
    	background: #f0f2f7;
    	z-index: -5;

    	.feedback-header {
    		min-height: 48px;

    		.text-btn {
    			font-family: Inter-SemiBold;
    			font-size: 14px;
    			color: #2974ff;
    			cursor: pointer;
    		}
    	}

    	.feedback-content {
    		border-top: 1px solid #dde2ee;
    		font-family: Inter-Regular;
    		font-size: 13px;
    		color: #7587a3;
    		line-height: 20px;

    		.reason-icon {
    			font-size: 16px;
    			color: #ffffff !important;
    			background: #7587a3;
    			opacity: 0.9;
    			height: 24px;
    			width: 24px;
    			border-radius: 50px;

    			img {
    				height: 16px;
    				filter: brightness(0) saturate(100%) invert(100%) sepia(46%) saturate(0%) hue-rotate(89deg) brightness(112%) contrast(101%);
    			}
    		}

    		.reason-text {
    			font-family: Inter-SemiBold;
    			font-size: 13px;
    			color: #7587a3;
    		}
    	}
    }
</style>